import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CheckboxField from './CheckboxField'
import Value from '../Value'
import DateCalendarField from './DateCalendarField'
import * as validations from '../../../utils/validations'
import SelectField from './SelectField'
import { useField } from 'react-final-form'
import useFieldValue from '../../../hooks/useFieldValue'
import Alert from '../Alert'

const nameByKind = {
  publish: 'publicación',
  unpublish: 'despublicación'
}

const PublishScheduleField = ({ kind, minimumDate, minHour = 0, notifyComplete, datePlaceholder }) => {
  const { input: destroyInput } = useField(`${kind}._destroy`)
  const checkValue = useFieldValue(`${kind}.value`)
  const checkDateValue = useFieldValue(`${kind}.date`)
  const minsToHour = hour => String(hour).padStart(2, '0') + ':00'
  const options = [...Array(24 - minHour).keys()].map(el => minsToHour(el + minHour)).map(o => ({ value: o, label: o }))

  useEffect(() => {
    if (checkValue) {
      destroyInput.onChange(undefined)
    } else {
      destroyInput.onChange(true)
    }
  }, [checkValue, destroyInput])

  return (
    <div>
      <CheckboxField name={`${kind}.value`} margin="none" label={`Fecha y hora de ${nameByKind[kind]}`} />
      <Value name={`${kind}.value`}>
        {({ value: scheduleValue }) =>
          scheduleValue && (
            <div className="flex flex-col md:flex-row md:gap-4 md:items-start gap-2 mt-2 justify-between">
              <div className="flex md:flex-row flex-col md:gap-4 gap-2 md:w-130 md:min-w-130">
                <DateCalendarField
                  name={`${kind}.date`}
                  minimumDate={minimumDate}
                  validate={validations.mix(validations.required(), validations.isDate())}
                  size="xl"
                  placeholder={datePlaceholder}
                />
                <SelectField
                  name={`${kind}.hour`}
                  options={checkDateValue ? options : []}
                  validate={validations.required()}
                  size="w-60"
                  emptyLabel="Selecciona una hora"
                  className="min-w-64 max-h-12"
                />
              </div>
              {notifyComplete && (
                <Alert type="info" className="md:mb-0 mb-4 max-w-160 font-normal" border>
                  Deberás subir la Política de Privacidad en el formulario, antes de la fecha de publicación programada,
                  caso contrario, <span className="font-extrabold">NO se realizará la publicación.</span>
                </Alert>
              )}
            </div>
          )
        }
      </Value>
    </div>
  )
}

export default PublishScheduleField

PublishScheduleField.propTypes = {
  kind: PropTypes.oneOf(['publish', 'unpublish']),
  minimumDate: PropTypes.object,
  minHour: PropTypes.number,
  notifyComplete: PropTypes.bool,
  datePlaceholder: PropTypes.string
}
