import React, { useEffect } from 'react'
import { format } from 'date-fns'
import PublishScheduleField from './PublishScheduleField'
import { useField } from 'react-final-form'
import { dateStringToObject, parseDayMonthYear } from '../../../utils/helpers'
import useFieldValue from '../../../hooks/useFieldValue'

const publishType = {
  publish: 'publish',
  unpublish: 'unpublish'
}

const PublishScheduleRangeField = () => {
  const today = format(new Date(), 'dd/MM/yyyy')
  const publishInputCheckValue = useFieldValue(`${publishType.publish}.value`)
  const { input: publishInput } = useField(`${publishType.publish}.date`)
  const { input: unpublishInput } = useField(`${publishType.unpublish}.date`)
  const { input: publishHourInput } = useField(`${publishType.publish}.hour`)
  const { input: unpublishHourInput } = useField(`${publishType.unpublish}.hour`)

  const todayDate = dateStringToObject(today)
  const datePublishValue = dateStringToObject(publishInput.value || today)

  const publishHourValue = publishHourInput.value?.split(':')[0]
  const unpublishHourValue = unpublishHourInput.value?.split(':')[0]

  const dateNow = new Date()
  const minStartHour = today === publishInput.value || publishInput.value === '' ? dateNow.getHours() + 1 : 0
  const minEndHour =
    unpublishInput.value === publishInput.value && publishHourValue ? parseInt(publishHourValue) + 1 : 0

  useEffect(() => {
    if (publishInput.value === unpublishInput.value && parseInt(publishHourValue) > parseInt(unpublishHourValue)) {
      unpublishHourInput.onChange(undefined)
    }
  }, [publishHourValue, publishInput.value, unpublishHourInput, unpublishHourValue, unpublishInput.value])

  useEffect(() => {
    if (parseDayMonthYear(publishInput.value) > parseDayMonthYear(unpublishInput.value)) {
      unpublishInput.onChange(undefined)
      unpublishHourInput.onChange(undefined)
    }
  }, [publishInput.value, unpublishHourInput, unpublishInput, unpublishInput.value])

  useEffect(() => {
    if (publishInputCheckValue) {
      publishInput.onChange(today)
      publishHourInput.onChange(String(minStartHour).padStart(2, '0') + ':00')
    }
  }, [publishInputCheckValue])

  return (
    <div className="ml-7">
      <PublishScheduleField
        kind={publishType.publish}
        minimumDate={todayDate}
        minHour={minStartHour}
        datePlaceholder="Desde"
        notifyComplete
      />
      <PublishScheduleField
        kind={publishType.unpublish}
        minimumDate={datePublishValue}
        minHour={minEndHour}
        datePlaceholder="Hasta"
      />
    </div>
  )
}

export default PublishScheduleRangeField

PublishScheduleRangeField.propTypes = {}
