import React from 'react'
import FieldTitle from '../../molecules/FieldTitle'
import CheckboxField from '../../molecules/fields/CheckboxField'
import Value from '../../molecules/Value'
import PublishScheduleRangeField from '../../molecules/fields/PublishScheduleRangeField'

const PublishSchedule = () => {
  return (
    <div>
      <FieldTitle
        title="Publicación y despublicación de la consulta rápida"
        hint={
          <CheckboxField
            name="has_published_schedule"
            margin="none"
            label="La consulta rápida se publicará y/o despublicará en la fecha y hora seleccionada."
          />
        }
      />
      <Value name="has_published_schedule">
        {({ value: hasScheduleValue }) => hasScheduleValue && <PublishScheduleRangeField />}
      </Value>
    </div>
  )
}

export default PublishSchedule
