import React from 'react'
import CheckboxField from '../../molecules/fields/CheckboxField'

const PublishScheduleFilter = () => {
  return (
    <>
      <label htmlFor="sort-input" className="font-bold mr-4">
        Programado
      </label>
      <CheckboxField name="scheduled_with" label="Publicación" value="publish" margin="none" />
      <CheckboxField name="scheduled_with" label="Despublicación" value="unpublish" margin="none" />
    </>
  )
}

export default PublishScheduleFilter
